export const RECHARGE = {
  CARD_DEBIT: 0,
  CARD_CREDIT: 1,
  CASH: 2,
  PRODUCTION: 3,
  COURTESY: 4,
  DIVIDED: 5,
  PIX: 6,
  MEAL_TICKET: 7,
  CASHLESS: 8,
} as const

export const SEX = {
  MASCULINO: 0,
  FEMININO: 1,
  INDEFINIDO: 2
} as const

export const ACTIVATION_TYPE = {
  COMMON: 0,
  INGRESSE: 1,
  ENTRANCE_PAY: 2,
  LINKED_NEW_CARD: 3,
} as const

export const ENTRANCE_PAY_TYPE = {
  ACTIVATION: 0,
  EARLY: 1,
} as const

export const DEVICE = {
  POS: 0,
  TOTEM: 1,
} as const

export function getTypePaymentInRealm(type_payment: string) {
  if (type_payment == 'card_debit') return RECHARGE.CARD_DEBIT;
  if (type_payment == 'card_credit') return RECHARGE.CARD_CREDIT;
  if (type_payment == 'cash') return RECHARGE.CASH;
  if (type_payment == 'production') return RECHARGE.PRODUCTION;
  if (type_payment == 'courtesy') return RECHARGE.COURTESY;
  if (type_payment == 'divided') return RECHARGE.DIVIDED;
  if (type_payment == 'pix') return RECHARGE.PIX;
  if (type_payment == 'mealTicket') return RECHARGE.MEAL_TICKET;
  if (type_payment == 'cashless') return RECHARGE.CASHLESS;
}

export function convertTypeIntToString(type_payment: number) {
  if (type_payment == RECHARGE.CARD_DEBIT) return 'card_debit';
  if (type_payment == RECHARGE.CARD_CREDIT) return 'card_credit';
  if (type_payment == RECHARGE.CASH) return 'cash';
  if (type_payment == RECHARGE.PRODUCTION) return 'production';
  if (type_payment == RECHARGE.COURTESY) return 'courtesy';
  if (type_payment == RECHARGE.DIVIDED) return 'divided';
  if (type_payment == RECHARGE.PIX) return 'pix';
  if (type_payment == RECHARGE.MEAL_TICKET) return 'mealTicket';
  if (type_payment == RECHARGE.CASHLESS) return 'cashless';
}

export function convertTypeIntToPTBR(type_payment: number) {
  if (type_payment == RECHARGE.CARD_DEBIT) return 'DÉBITO';
  if (type_payment == RECHARGE.CARD_CREDIT) return 'CRÉDITO';
  if (type_payment == RECHARGE.CASH) return 'DINHEIRO';
  if (type_payment == RECHARGE.PRODUCTION) return 'PRODUÇÃO';
  if (type_payment == RECHARGE.COURTESY) return 'CORTESIA';
  if (type_payment == RECHARGE.DIVIDED) return 'DIVIDIDO';
  if (type_payment == RECHARGE.PIX) return 'PIX';
  if (type_payment == RECHARGE.MEAL_TICKET) return 'VALE REFEIÇÃO';
  if (type_payment == RECHARGE.CASHLESS) return 'CONSUMO';
}

export function convertSexIntToPTBR(sex: number) {
  if (sex == SEX.MASCULINO) return 'MASCULINO';
  if (sex == SEX.FEMININO) return 'FEMININO';
  if (sex == SEX.INDEFINIDO) return 'INDEFINIDO';
}
