import { ACTIVATION_TYPE, RECHARGE, SEX } from "../../../variables/paymentType";
import ActivationsNFC from "../entities/activationsNFC.entity";
import BalanceReversal from "../entities/balanceReversal.entity";
import EntrancePay from "../entities/entrancesPay.entity";
import Recharge from "../entities/recharge.entity";
import Sales from "../entities/sales.entity";
import SalesTotem from "../entities/salesTotem.entity";
import TaxDevolution from "../entities/taxaDevolution.entity";

export function calculateActivation(activationsNFCs: ActivationsNFC[]) {
  const cartoesHabilitados = activationsNFCs.length
  let taxaAtivacao = 0;
  let balanceIngresse = 0;
  let saldoGeralSemVinculaNewNfc = 0;

  activationsNFCs.forEach(item => {
    taxaAtivacao += item.activation_rate
    if(item.type !== ACTIVATION_TYPE.LINKED_NEW_CARD)
      saldoGeralSemVinculaNewNfc += item.activation_rate + item.balance
    if(item.type === ACTIVATION_TYPE.INGRESSE || (item.type === ACTIVATION_TYPE.COMMON && item.balance > 0))
      balanceIngresse += item.activation_rate + item.balance
  })

  return { cartoesHabilitados, taxaAtivacao, balanceIngresse, saldoGeralSemVinculaNewNfc }
}

export function calculateEntrances(entrancesPays: EntrancePay[]) {
  let count = 0;
  let consummation = 0;
  let returned = 0;

  let cash = 0;
  let card_credit = 0;
  let card_debit = 0;
  let courtesy = 0;
  entrancesPays.forEach(item => {
    if(item.revenue_return) {
      returned += returned
      return;
    }
    
    if(item.type_payment === RECHARGE.CASH) {
      cash += item.entrace
      consummation += item.consummation
    } else if(item.type_payment === RECHARGE.CARD_CREDIT)  {
      card_credit += item.entrace
      consummation += item.consummation
    } else if(item.type_payment === RECHARGE.CARD_DEBIT) {
      card_debit += item.entrace
      consummation += item.consummation
    } else if(item.type_payment === RECHARGE.COURTESY) {
      courtesy += item.entrace
      consummation += item.consummation
    } else if(item.type_payment === RECHARGE.DIVIDED) {
      item.splitPay.forEach(pay => {
        if(pay.revenue_return)
          returned += pay.value
        else if(pay.type_payment === RECHARGE.CASH)
          cash += pay.value
        else if(pay.type_payment === RECHARGE.CARD_CREDIT)
          card_credit += pay.value
        else if(pay.type_payment === RECHARGE.CARD_DEBIT)
          card_debit += pay.value
        else if(pay.type_payment === RECHARGE.COURTESY)
          courtesy += pay.value
      })
      if(!item.splitPay.find(pay => pay.revenue_return)) {
        consummation += item.consummation
      }
    }

    count += (item.earlyEntries && item.earlyEntries.length > 0) ? item.earlyEntries.length : 1
  })

  const total = cash + card_credit + card_debit + courtesy;

  return { cash, card_credit, card_debit, courtesy, total, count, consummation, returned }
}

export function calculateRecharges(recharges: Recharge[]) {
  const count = recharges.length;
  let returned = 0;

  let cash = 0;
  let card_credit = 0;
  let card_debit = 0;
  let courtesy = 0;
  let pix = 0;
  recharges.forEach(item => {
    if(item.reversed)
      returned += item.value
    else if(item.type_payment === RECHARGE.CASH)
      cash += item.value
    else if(item.type_payment === RECHARGE.CARD_CREDIT)
      card_credit += item.value
    else if(item.type_payment === RECHARGE.CARD_DEBIT)
      card_debit += item.value
    else if(item.type_payment === RECHARGE.COURTESY)
      courtesy += item.value
    else if(item.type_payment === RECHARGE.PIX)
      pix += item.value
  })

  const total = cash + card_credit + card_debit + courtesy + pix;

  return { cash, card_credit, card_debit, courtesy, total, count, returned, pix }
}

export function calculateConsume(sales: Sales[]) {
  const paymentsValues = {
    cash: 0,
    credit: 0,
    debit: 0,
    courtesy: 0,
    pix: 0,
    cashless: 0,
    total: 0,
    total_consume: 0,
  }


  let service_rate = 0;
  let total_chargeBack = 0;
  const countSales = sales.length;
  sales.forEach(item => {
    service_rate += item.service_rate
    const chargeBack = item.products.reduce((prev, curr) => curr.reversed ? prev + curr.unitary_value : prev, 0)
    total_chargeBack += chargeBack

    if(item.payment_type !== undefined) {
      sumSalePayment(item, paymentsValues)
      if (item.payment_type === RECHARGE.CASH) {
        // paymentsValues.total_consume -= chargeBack
        paymentsValues.cash -= chargeBack
      } else  if (item.payment_type === RECHARGE.CASHLESS) {
        paymentsValues.total_consume -= chargeBack
        paymentsValues.cashless -= chargeBack
      }
    } else {
      paymentsValues.total_consume += item.total_price - chargeBack
    }
  })

  paymentsValues.total = paymentsValues.cash
                        + paymentsValues.credit
                        + paymentsValues.debit
                        + paymentsValues.courtesy
                        + paymentsValues.pix

  return { service_rate, total_chargeBack, countSales, ...paymentsValues }
}

export function calculateSalesTotem(sales: SalesTotem[]) {
  const paymentsValues = {
    cash: 0, 
    credit: 0, 
    debit: 0, 
    pix: 0, 
    courtesy: 0, 
    total: 0
  }

  sales.forEach(item => {
    if(item.revenue_return) return;
    if(item.payment_type === RECHARGE.CARD_CREDIT)
      return paymentsValues.credit += item.total_price;
    if(item.payment_type === RECHARGE.CARD_DEBIT)
      return paymentsValues.debit += item.total_price;
    if(item.payment_type === RECHARGE.PIX)
      return paymentsValues.pix += item.total_price;
  })

  paymentsValues.total = paymentsValues.cash
                        + paymentsValues.credit
                        + paymentsValues.debit
                        + paymentsValues.pix

  return paymentsValues;
}

export function calculateBalanceReversal(balanceReversals: BalanceReversal[]) {
  return balanceReversals.reduce((prev, curr) => prev + curr.value, 0)
}

export function calculateTaxDevolution(taxaDevolutions: TaxDevolution[]) {
  const total = taxaDevolutions.reduce((prev, curr) => prev + curr.tax_value, 0)
  const count = taxaDevolutions.length;
  return { count, total };
}


function sumSalePayment(sale: Sales, paymentsValues: any) {
  if(sale.revenue_return) return;

  const sumToPaymentType = (value: number, payment_type: number) => {
    if(payment_type === RECHARGE.CARD_CREDIT)
      return paymentsValues.credit += value;
    if(payment_type === RECHARGE.CARD_DEBIT)
      return paymentsValues.debit += value;
    if(payment_type === RECHARGE.CASH)
      return paymentsValues.cash += value;
    if(payment_type === RECHARGE.COURTESY)
      return paymentsValues.courtesy += value;
    if(payment_type === RECHARGE.PIX)
      return paymentsValues.pix += value;
    if(payment_type === RECHARGE.CASHLESS)
      return paymentsValues.total_consume += value;
  }

  if(sale.payment_type === RECHARGE.DIVIDED)
    sale.splitPay?.forEach(item => {
      if(!item.revenue_return)
        sumToPaymentType(item.value, item.type_payment)
    })
  else
    sumToPaymentType(sale.total_price, sale.payment_type as number)
}