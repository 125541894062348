import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './polyfill';
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import 'semantic-ui-css/semantic.min.css'

import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';

document.cookie = 'cross-site-cookie=bar;SameSite=None; Secure';

ReactDOM.render(<App />, document.getElementById('root'));
