import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  setValueComposition: ['value'],
});
export const EstablishmentTypes = Types;
export default Creators;

const INITIAL_STATE = Immutable(0);

const setValueComposition = (state = INITIAL_STATE, { data }) => {
  return data.value;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_VALUE_COMPOSITION]: setValueComposition,
});
