import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  addCompositionPage: ['stock'],
  removeCompositionPage: ['stock'],
});
export const EstablishmentTypes = Types;
export default Creators;

const INITIAL_STATE = Immutable([]);

const addCompositionPage = (state = INITIAL_STATE, { data }) => {
  if (state.some((element) => element.page === data.page)) {
    const newState = state.filter((element) => element.page !== data.page);
    return [...newState, { ...data }];
  } else return [...state, { ...data }];
};

const removeCompositionPage = () => {
  return [];
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_COMPOSITION_PAGE]: addCompositionPage,
  [Types.REMOVE_COMPOSITION_PAGE]: removeCompositionPage,
});
