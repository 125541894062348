import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import TaxDevolution from "../entities/taxaDevolution.entity";
import FirestorePipe from "../utils/pipe";

class TaxDevolutionDB extends FirestorePipe {

  public static readonly colName = 'TaxDevolution'

  constructor(idEstablishment: number, idEvent: number) {
    super(TaxDevolutionDB.colName, idEstablishment, idEvent);
  }

  public getAll(): Promise<TaxDevolution[]> {
    return this._getAll<TaxDevolution>();
  }
  public get(uid: string): Promise<TaxDevolution> {
    return this._get(uid);
  }
  public on(listener: (data: TaxDevolution[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default TaxDevolutionDB;