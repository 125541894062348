import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  handleEventToUpdateSelected: ['event'],
});

export const EventTypes = Types;
export default Creators;

const INITIAL_STATE = Immutable([]);

const handleEventToUpdateSelected = (state = INITIAL_STATE, event) => {
  return event.selected;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.HANDLE_EVENT_TO_UPDATE_SELECTED]: handleEventToUpdateSelected,
});
