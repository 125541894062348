import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  handleEventsSelecteds: ['events'],
  filterEventsByEstablishments: ['events'],
});
export const EventTypes = Types;
export default Creators;

const INITIAL_STATE = Immutable([]);

const handleEventsSelecteds = (state = INITIAL_STATE, events) => {
  return events.selecteds;
};

const filterByEstablishments = (state = INITIAL_STATE, payload = []) => {
  const filteredEvents = state.filter(event => payload.establishmentsIDs.includes(event.establishment_id));
  return filteredEvents;
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.HANDLE_EVENTS_SELECTEDS]: handleEventsSelecteds,
  [Types.FILTER_EVENTS_BY_ESTABLISHMENTS]: filterByEstablishments,
});
