import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  addEstablishments: ['establishment'],
  removeEstablishments: ['establishment'],
});
export const EstablishmentTypes = Types;
export default Creators;

const INITIAL_STATE = Immutable([]);

const addEstablishments = (state = INITIAL_STATE, { establishment }) => {
  return [...state, { ...establishment }];
};

const removeEstablishments = (state = INITIAL_STATE, { establishment }) => {
  return state.filter((state) => state.id !== establishment.id);
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_ESTABLISHMENTS]: addEstablishments,
  [Types.REMOVE_ESTABLISHMENTS]: removeEstablishments,
});
