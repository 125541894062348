import axios from 'axios';
import { getToken } from './auth';

const api = axios.create({
  //  baseURL: 'http://localhost:3333',
  baseURL: 'https://production-uget-7n3oeyxokq-uc.a.run.app/'
  // baseURL: 'https://dev-uget-7n3oeyxokq-uc.a.run.app',

});
api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
export default api;
