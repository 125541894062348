import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import SalesTotem from "../entities/salesTotem.entity";
import FirestorePipe from "../utils/pipe";

class SalesTotemDB extends FirestorePipe {

  public static readonly colName = 'SalesToten'

  constructor(idEstablishment: number, idEvent: number) {
    super(SalesTotemDB.colName, idEstablishment, idEvent);
  }

  public getAll(): Promise<SalesTotem[]> {
    return this._getAll<SalesTotem>();
  }
  public get(uid: string): Promise<SalesTotem> {
    return this._get(uid);
  }
  public on(listener: (data: SalesTotem[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default SalesTotemDB;