import React, { createContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import app from '../database/firestore/config';
import api from '../services/api';
import { useSelector } from 'react-redux';
import { Unsubscribe, where } from 'firebase/firestore';
import FireContextInterface from '../interfaces/fireContext'
import { calculateActivation, calculateBalanceReversal, calculateConsume, calculateEntrances, calculateRecharges, calculateSalesTotem, calculateTaxDevolution } from '../database/firestore/utils/calculateData';

import ActivationsNFC from '../database/firestore/entities/activationsNFC.entity'
import BalanceReversal from '../database/firestore/entities/balanceReversal.entity'
import CashReturn from '../database/firestore/entities/cashReturn.entity'
import CashierBleed from '../database/firestore/entities/cashierBleed.entity'
import EntrancesPay from '../database/firestore/entities/entrancesPay.entity'
import Rechange from '../database/firestore/entities/recharge.entity'
import Sales from '../database/firestore/entities/sales.entity'
import TaxaDevolution from '../database/firestore/entities/taxaDevolution.entity'
import ThrowNfcCard from '../database/firestore/entities/throwNfcCard.entity'
import ActivationsNFCDB from '../database/firestore/wrappers/activationsNFC';
import BalanceReversalDB from '../database/firestore/wrappers/balanceReversal';
import CashReturnDB from '../database/firestore/wrappers/cashReturn';
import CashierBleedDB from '../database/firestore/wrappers/cashierBleed';
import EntrancePayDB from '../database/firestore/wrappers/entrancesPay';
import RechargeDB from '../database/firestore/wrappers/rechange';
import SalesDB from '../database/firestore/wrappers/sales';
import TaxDevolutionDB from '../database/firestore/wrappers/taxaDevolution';
import ThrowNfcCardDB from '../database/firestore/wrappers/throwNfcCard';
import User from '../interfaces/user';
import Entrance from '../interfaces/entrance';
import Cashier from '../interfaces/cashier';
import Import from '../classes/import';
import TotemRecarga from '../interfaces/totemRecarga';
import SalesTotemDB from '../database/firestore/wrappers/salesTotem';
import SalesTotem from '../database/firestore/entities/salesTotem.entity';
import Totem from '../interfaces/totem';
import dateFormat from 'dateformat';

export const FireContext = createContext<FireContextInterface>({} as any);

export default function FireProvider({ children }: any) {

  const eventsSelecteds = useSelector((state: any) => state.event);
  
  const [userAuth, setuserAuth] = useState<any>(null)
  const [activationsNFCs, setactivationsNFCs] = useState<ActivationsNFC[]>([])
  const [balanceReversals, setbalanceReversals] = useState<BalanceReversal[]>([])
  const [cashReturns, setcashReturns] = useState<CashReturn[]>([])
  const [cashierBleeds, setcashierBleeds] = useState<CashierBleed[]>([])
  const [entrancesPays, setentrancesPays] = useState<EntrancesPay[]>([])
  const [recharges, setrecharges] = useState<Rechange[]>([])
  const [sales, setsales] = useState<Sales[]>([])
  const [salesTotem, setsalesTotem] = useState<SalesTotem[]>([])
  const [taxaDevolutions, settaxaDevolutions] = useState<TaxaDevolution[]>([])
  const [throwNfcCards, setthrowNfcCards] = useState<ThrowNfcCard[]>([])
  // Firesotre valores calculados
  const [values_activation, setvalues_activation] = useState({
    taxaAtivacao: 0, cartoesHabilitados: 0, balanceIngresse: 0, saldoGeralSemVinculaNewNfc: 0
  })
  const [values_entrance, setvalues_entrance] = useState({
    cash: 0, card_credit: 0, card_debit: 0, courtesy: 0, total: 0, count: 0, consummation: 0, returned: 0,
  })
  const [values_recharges, setvalues_recharges] = useState({
    cash: 0, card_credit: 0, card_debit: 0, courtesy: 0, total: 0, count: 0, returned: 0, pix: 0
  })
  const [values_sales, setvalues_sales] = useState({
    service_rate: 0, total_chargeBack: 0, total_consume: 0, countSales: 0, cash: 0, credit: 0, debit: 0, courtesy: 0, pix: 0, total: 0
  })
  const [values_salesTotem, setvalues_salesTotem] = useState({
    cash: 0, credit: 0, debit: 0, courtesy: 0, pix: 0, total: 0
  })
  const [values_balanceReversal, setvalues_balanceReversal] = useState(0)
  const [values_taxaDevo, setvalues_taxaDevo] = useState({
    total: 0, count: 0
  })
  // Postgress
  const [users, setusers] = useState<User[]>([])
  const [users_dicionary, setusers_dicionary] = useState<any>({})
  const [cashiers, setcashiers] = useState<Cashier[]>([])
  const [cashiers_dicionary, setcashiers_dicionary] = useState<any>({})
  const [entrances, setentrances] = useState<Entrance[]>([])
  const [totems, settotems] = useState<Totem[]>([])
  const [totems_dicionary, settotems_dicionary] = useState<any>({})
  const [totemsRecarga, settotemsRecarga] = useState<TotemRecarga[]>([])
  const [totemsRecarga_dicionary, settotemsRecarga_dicionary] = useState<any>({})
  const [nfcs_dicionary, setnfcs_dicionary] = useState<any>({})
  const [startDate, setstartDate] = useState<any>(dateFormat(new Date(), 'yyyy-mm-dd'))
  const [endDate, setendDate] = useState<any>(dateFormat(new Date(), 'yyyy-mm-dd'))

  useEffect(() => {
    const auth = getAuth(app);
    const unSubscribe = onAuthStateChanged(auth, user => setuserAuth(user))
    
    return unSubscribe;
  },[])

  useEffect(() => {
    if(eventsSelecteds.length === 0 || !userAuth) return;
    const { establishment_id, id } = eventsSelecteds[0]

    const firstDate = new Date(startDate)
    firstDate.setDate(firstDate.getDate() + 1)
    firstDate.setHours(0,0,0);

    const secondDate = new Date(endDate)
    secondDate.setDate(secondDate.getDate() + 1)
    secondDate.setHours(23,59,59);
    
    const wheres = [
      where('create_at', '>', firstDate),
      where('create_at', '<', secondDate),
    ];

    const onSubscibes: Unsubscribe[] = [];
    onSubscibes.push(new ActivationsNFCDB(establishment_id, id).on(setactivationsNFCs, ...wheres));
    onSubscibes.push(new BalanceReversalDB(establishment_id, id).on(setbalanceReversals, ...wheres));
    onSubscibes.push(new CashReturnDB(establishment_id, id).on(setcashReturns, ...wheres));
    onSubscibes.push(new CashierBleedDB(establishment_id, id).on(setcashierBleeds, ...wheres));
    onSubscibes.push(new EntrancePayDB(establishment_id, id).on(setentrancesPays, ...wheres));
    onSubscibes.push(new RechargeDB(establishment_id, id).on(setrecharges, ...wheres));
    onSubscibes.push(new SalesDB(establishment_id, id).on(setsales, ...wheres));
    onSubscibes.push(new SalesTotemDB(establishment_id, id).on(setsalesTotem, ...wheres));
    onSubscibes.push(new TaxDevolutionDB(establishment_id, id).on(settaxaDevolutions, ...wheres));
    onSubscibes.push(new ThrowNfcCardDB(establishment_id, id).on(setthrowNfcCards, ...wheres));

    return () => {
      console.log('Fechou listeners');
      onSubscibes.forEach(onSub => onSub());
    }
  }, [eventsSelecteds, userAuth, startDate, endDate]);

  useEffect(() => {
    if(eventsSelecteds.length === 0) return;

    const { id, establishment_id } = eventsSelecteds[0];

    const importData = new Import(id, establishment_id);

    importData.importCashiers().then(data => {
      setcashiers(data.cashiers);
      setcashiers_dicionary(data.cashiers_dicionary)
    })
    importData.importTotems().then(data => {
      settotems(data.totems)
      settotems_dicionary(data.totems_dicionary)
    })
    importData.importTotemsRecarga().then(data => {
      settotemsRecarga(data.totemsRecarga)
      settotemsRecarga_dicionary(data.totemsRecarga_dicionary)
    })
    importData.importEntrances().then(setentrances)
  }, [eventsSelecteds]);

  // Calculos para cada atualização no firestore
  useEffect(() => setvalues_activation(calculateActivation(activationsNFCs)), [activationsNFCs])
  useEffect(() => setvalues_entrance(calculateEntrances(entrancesPays)), [entrancesPays])
  useEffect(() => setvalues_recharges(calculateRecharges(recharges)), [recharges])
  useEffect(() => setvalues_sales(calculateConsume(sales)), [sales])
  useEffect(() => setvalues_salesTotem(calculateSalesTotem(salesTotem)), [salesTotem])
  useEffect(() => setvalues_balanceReversal(calculateBalanceReversal(balanceReversals)), [balanceReversals])
  useEffect(() => setvalues_taxaDevo(calculateTaxDevolution(taxaDevolutions)), [taxaDevolutions])

  // Dicionary
  useEffect(() => {
    const nfcs_dicionary: any = {}
    activationsNFCs.forEach(nfc => nfcs_dicionary[nfc.idCard] = nfc)
    setnfcs_dicionary(nfcs_dicionary)
  }, [activationsNFCs])

  return (
    <FireContext.Provider value={{
      activationsNFCs,
      balanceReversals,
      cashReturns,
      cashierBleeds,
      entrancesPays,
      recharges,
      sales,
      salesTotem,
      taxaDevolutions,
      throwNfcCards,

      values_activation,
      values_entrance,
      values_recharges,
      values_sales,
      values_salesTotem,
      values_balanceReversal,
      values_taxaDevo,

      users,
      users_dicionary,
      cashiers,
      cashiers_dicionary,
      entrances,
      nfcs_dicionary,
      totems,
      totems_dicionary,
      totemsRecarga,
      totemsRecarga_dicionary,

      startDate,
      endDate,
      setstartDate,
      setendDate,
    }}>
      { children }
    </FireContext.Provider>
  )
};

