import { createActions, createReducer } from 'reduxsauce';
// import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  handleKitchenSelected: ['kitchen'],
  handleArrayKitchensSelecteds: ['kitchens'],
  handleScrapSelected: ['scrap']
});

export const KitchenTypes = Types;
export default Creators;

const INITIAL_STATE = {};
const ARRAY_KITCHENS_INITIAL_STATE = [];
const SCRAP_INTITIAL_STATE = {};

const handleKitchenSelected = (state = INITIAL_STATE, kitchen) => {
  return kitchen.selected;
};

const handleArrayKitchensSelecteds = (state = ARRAY_KITCHENS_INITIAL_STATE, { ids }) => {

  if (ids === -1)
    return state = [];

  const indexId = state.findIndex(index => Number(index) === Number(ids))

  if (indexId > -1) {
    return state.filter(id => Number(id) !== Number(ids));
  }
  else {
    return [...state, ids];
  }
};

const handleScrapSelected = (state = SCRAP_INTITIAL_STATE, scrap) => {
  return scrap.selected;
};


export const reducer = createReducer(INITIAL_STATE, {
  [Types.HANDLE_KITCHEN_SELECTED]: handleKitchenSelected,
});

export const reducerArray = createReducer(ARRAY_KITCHENS_INITIAL_STATE, {
  [Types.HANDLE_ARRAY_KITCHENS_SELECTEDS]: handleArrayKitchensSelecteds,
});

export const reducerScrap = createReducer(INITIAL_STATE, {
  [Types.HANDLE_SCRAP_SELECTED]: handleScrapSelected,
});
