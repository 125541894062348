import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  handleSettingsSelected: ['dates'],
});

export const DateTypes = Types;
export default Creators;

const INITIAL_STATE = {
  initialDate: '',
  finalDate: '',
  page: 1,
  perPage: 15
};

const handleSettingsSelected = (state = INITIAL_STATE, data) => {
  console.log('data redux', data)
  state = data;
  return state;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.HANDLE_SETTINGS_SELECTED]: handleSettingsSelected,
});
