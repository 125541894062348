import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import CashierBleed from "../entities/cashierBleed.entity";
import FirestorePipe from "../utils/pipe";

class CashierBleedDB extends FirestorePipe {

  public static readonly colName = 'CashierBleed'

  constructor(idEstablishment: number, idEvent: number) {
    super(CashierBleedDB.colName, idEstablishment, idEvent);
  }

  public getAll(): Promise<CashierBleed[]> {
    return this._getAll<CashierBleed>();
  }
  public get(uid: string): Promise<CashierBleed> {
    return this._get(uid);
  }
  public on(listener: (data: CashierBleed[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default CashierBleedDB;