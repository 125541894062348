import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import BalanceReversal from "../entities/balanceReversal.entity";
import FirestorePipe from "../utils/pipe";

class BalanceReversalDB extends FirestorePipe {

  public static readonly colName = 'BalanceReversal'

  constructor(idEstablishment: number, idEvent: number) {
    super(BalanceReversalDB.colName, idEstablishment, idEvent);
  }

  public getAll(): Promise<BalanceReversal[]> {
    return this._getAll<BalanceReversal>();
  }
  public get(uid: string): Promise<BalanceReversal> {
    return this._get(uid);
  }
  public on(listener: (data: BalanceReversal[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default BalanceReversalDB;