import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  eventChange: ['change'],
});
export const EventTypes = Types;
export default Creators;

const INITIAL_STATE = Immutable({
  menuList: null,
  establishmentList: null,
});

const eventChange = (state = INITIAL_STATE, { change }) => {
  const { option, value } = change;
  switch (option) {
    case 'establishmentList':
      return INITIAL_STATE.set('establishmentList', value);
    case 'menuList':
      return INITIAL_STATE.set('menuList', value);

    default:
      return;
  }
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EVENT_CHANGE]: eventChange,
});
