// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyATOazHCnbfg4XFw0tb_XNqCka9CVxs0Ec",
  authDomain: "uget-b56f7.firebaseapp.com",
  databaseURL: "https://uget-b56f7.firebaseio.com",
  projectId: "uget-b56f7",
  storageBucket: "uget-b56f7.appspot.com",
  messagingSenderId: "35162894479",
  appId: "1:35162894479:web:e84e71b4bae81449bec0fa",
  measurementId: "G-NF3FHDPWBN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;