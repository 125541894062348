import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';
import { reducer as establishment } from './establishment';
import { reducer as event } from './event';
import { reducer as eventUpdate } from './eventUpdate';
import { reducer as compositionProducts } from './compositionProducts';
import { reducer as compositionValue } from './compositionValue';
import { reducer as compositionProductsEdit } from './compositionProductsEdit';
import { reducer as eventChange } from './eventChange';
import { reducer as kitchen } from './kitchen';
import { reducerArray as kitchensIds } from './kitchen';
import { reducerScrap as scrap } from './kitchen';
import { reducer as data } from './settingsDashCommand';

const rootPersistConfig = {
  key: 'root',
  keyPrefix: '@UGET:',
  storage: localforage,
};

const rootReducer = combineReducers({
  establishment,
  event,
  eventUpdate,
  eventChange,
  kitchen,
  kitchensIds,
  scrap,
  compositionProducts,
  compositionProductsEdit,
  compositionValue,
  data,
});

export default persistReducer(rootPersistConfig, rootReducer);
