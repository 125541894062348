import api from "../services/api";
import Cashier from "../interfaces/cashier";
import Entrance from "../interfaces/entrance";
import queryString from 'querystring';
import TotemRecarga from "../interfaces/totemRecarga";
import Totem from "../interfaces/totem";

class Import {

  constructor(
    private event_id: number,
    private establishment_id: number
  ){}

  async importCashiers(): Promise<{ cashiers: Cashier[], cashiers_dicionary: any }> {
    const res = await api.get(`events/${this.event_id}/cashiers`)
    const cashiers: Cashier[] = res.data;
    const cashiers_dicionary: any = {}

    cashiers.forEach((user) => {
      cashiers_dicionary[user.id] = user
    })
    
    return { cashiers, cashiers_dicionary };
  }

  async importEntrances(): Promise<Entrance[]> {
    const res = await api.get(`entrance/event/${this.event_id}?showAll=true`)
    return res.data;
  }

  async importTotemsRecarga() {
    const res = await api.get(
      `/totem-recarga?${queryString.stringify({
        establishment_id: this.establishment_id,
      })}`
    );
    const totemsRecarga: TotemRecarga[] = res.data;
    const totemsRecarga_dicionary: any = {};

    totemsRecarga.forEach(item => {
      totemsRecarga_dicionary[item.id] = item
    })

    return { totemsRecarga, totemsRecarga_dicionary };
  }

  async importTotems() {
    console.log(`/totem?events=[${this.event_id}]`);
    
    const res = await api.get(`/totem?events=[${this.event_id}]`);

    const totems: Totem[] = res.data;
    const totems_dicionary: any = {};
    console.log('TOTEMS ', totems);

    totems.forEach(item => {
      totems_dicionary[item.id] = item
    })

    return { totems, totems_dicionary }; 
  }

}

export default Import;