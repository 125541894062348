import app from "../config";
import { getFirestore, collection, getDocs, query, QueryConstraint, getDoc, DocumentReference, DocumentData, doc, Unsubscribe, onSnapshot } from "firebase/firestore"

abstract class FirestorePipe {

  private readonly collectionName
  protected readonly colRef

  constructor(colName: string, idEstablishment: number, idEvent: number) {
    this.collectionName = colName;
    this.colRef = collection(getFirestore(app), `Establishments/${idEstablishment}/Events/${idEvent}/${colName}`);
  }

  private dbDoc(uid: string): DocumentReference<DocumentData> {
    return doc(getFirestore(app), this.collectionName, uid)
  }

  protected async _getAll<T>(...params: QueryConstraint[]): Promise<T[]> {
    const snapshot = await getDocs(query(this.colRef, ...params))
    const datas: T[] = [];
    snapshot.forEach(doc => datas.push({ uid: doc.id, ...doc.data() as any }))
    return datas;
  }

  protected async _get<T>(uid: string): Promise<T> {
    const docSnap = await getDoc(this.dbDoc(uid))
    if(!docSnap.exists())
      throw new Error("Register not found");
    return {
      uid: docSnap.id,
      ...docSnap.data(),
    } as any
  }

  protected _on<T>(listener: (data: T[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return onSnapshot(query(this.colRef, ...params), snapshot => {
      const datas: T[] = [];
      snapshot.forEach(doc => datas.push({ id: doc.id, ...doc.data() as any } as T))
      listener(datas)
    })
  }


  abstract getAll(...params: QueryConstraint[]): Promise<any[]>;
  abstract get(uid: string): Promise<any>;
  abstract on(listener: (data: any[]) => void): Unsubscribe;
} 

export default FirestorePipe;